/***
 ** Global Component Styling
***/

html {
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

a {
    color: var(--primaryColor);
    text-decoration: none;
    transition: 0.5s ease;
}

a:hover {
    text-decoration: underline;
    color: var(--tableText);
}

div.hidden {
    display: none;
}

:focus {
    outline: 0;
}

body {
    margin: 0rem;
    background-color: var(--background);
    color: var(--tableText);
    font-weight: 400;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 0.875rem;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto !important;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

div {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-repeat: no-repeat;
}

main {
    position: absolute;
    padding: 1.25rem;
    padding-top: 0.875rem;
    padding-left: 2.1875rem;
    min-width: 53.4375rem;
    padding-right: 2.1875rem;
    top: 0rem;
    left: 3.75rem;
    right: 0rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    min-height: calc(100%);
    transition: var(--transition);
    background-color: var(--navigation);
}

main.noPad {
    left: 0rem;
}

main.shaded {
    background-color: var(--background);
}

main.open {
    left: 23.75rem;
}
/*
@media only screen and (max-width: 82.5rem) {
  main.open.newNav {
    left: 8.125rem;
  }
}
*/

main .header {
    position: relative;
    width: 100%;
    display: inline-block;
    clear: both;
}

main .area {
    position: relative;
    width: 100%;
    display: inline-block;
    clear: both;
}

.primary {
    color: var(--primaryColor);
}

.secondary {
    color: var(--secondaryColor);
}

.hidden {
    display: none !important;
}

.menu {
    background-color: var(--menu);
    border-radius: 0.4375rem;
    box-shadow: 0 0 0 1px var(--shadow), 0 0.5rem 1rem var(--shadow);
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--white);
    letter-spacing: 1px;
    height: 0rem;
    opacity: 0;
    transition: 0.2s;
    z-index: 50;
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
}

.menu.open {
    height: unset;
    opacity: 1;
}

.menu .link {
    cursor: pointer;
    line-height: 2.25rem;
    white-space: nowrap;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    transition: 0.2s;
}

.menu .link:hover {
    background-color: var(--primaryColor);
}

.menu .menutitle {
    opacity: 0.8;
    white-space: nowrap;
    line-height: 2.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: var(--text);
}

.hover {
    white-space: nowrap;
    position: absolute;
    pointer-events: none;
    left: 3.375rem;
    top: 0.4375rem;
    background-color: var(--menu);
    padding: 0.625rem;
    border-radius: 0.25rem;
    box-shadow: var(--shadows);
    font-size: 0.875rem;
    color: var(--white);
    letter-spacing: 1px;
    height: 0rem;
    opacity: 0;
    font-family: Open Sans;
    font-weight: 600;
    transition: 0.2s;
}

.hover.open {
    height: unset;
    opacity: 1;
}

.ag-wrapper.ag-checkbox-input-wrapper:active {
  outline: none;
  box-shadow: none;
}
